body {
  background-color: #110f16;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  height: 100%;
  width: 100%;
  color: white;
}
.container {
  position: relative;
}

.page {
  position: relative;
  height: 100%;
  top: 2.5rem;
  margin-bottom: 150px;
  overflow: hidden;
  left: 0;
  right: 0;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 400ms, transform 400ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 200ms, transform 200ms;
}

.dropdown-menu {
  @media (min-width: 992px) {
    width: 30rem !important;
  }
  @media (max-width: 992px) {
    width: 25rem !important;
  }
  @media (max-width: 768px) {
    width: 20rem !important;
  }
}

// LOGIN COMPONENT
.message {
  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
  @media (max-width: 992px) {
    font-size: 1rem;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
  width: 100%;
  font-family: "Bungee Spice", cursive;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-container {
  width: 100%;
  padding: 2rem, 5rem;
  margin: 0 auto;
}

.login-row {
  display: flex;
  width: 100%;
  padding: 2rem, 5rem;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.login-col {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.login-button,
.logout-button {
  height: 3rem;
  width: 90%;
}
