.Title {
  text-transform: uppercase;
  font-size: 2rem;
  color: rgb(255, 255, 255);
  position: relative;
  padding-bottom: 1rem;
  letter-spacing: 3px;
  &::before {
    content: "";
    bottom: 0;
    position: absolute;
    left: 0;
    background-color: #0381ff38;
    width: 6rem;
    height: 0.4rem;
    border-radius: 50px;
  }
  &::after {
    content: "";
    bottom: 0;
    position: absolute;
    left: 0;
    background-color: #037fff;
    width: 3rem;
    height: 0.4rem;
    border-radius: 50px;
  }
  span {
    position: absolute;
    top: 15%;
    left: 0;
    font-size: 4rem;
    opacity: 0.07;
  }
}

.ImageSection {
  color: #f0f5f9;
  display: flex;
  margin-top: 5rem;
  margin-bottom: 5rem;
  .img {
    width: 100%;
    margin-right: 5%;
  }
  img {
    width: 100%;
    object-fit: contain;
    height: 100%;
    max-height: 35vh;
  }
  @media screen and (max-width: 1270px) {
    img {
      max-height: 25vh;
      margin-bottom: 5%;
    }
  }
  .resume,
  .linkedIn {
    margin-top: 20px;
    width: 100%;

    span {
      padding: 0 1%;
      position: absolute;
      font-size: 1.5rem;
      font-weight: 300;
      color: white;
    }

    @media screen and (max-width: 1270px) {
      width: 100%;
    }
  }
  .linkedIn {
    svg {
      margin-left: -0.25rem;
      width: 3.5rem;
      height: 3.5rem;
      color: #037fff;
    }
  }
  .icon-holder {
    display: block;
    font-size: 1.5em;
    text-align: center;
    margin-top: 10%;
    .icon {
      font-size: 2.8rem;
      color: #526291;
      transition: all 0.4s ease-in-out;
      padding-right: 5%;
      margin-bottom: 10%;
    }
    :hover {
      transition: all 0.4s ease-in-out;
      text-decoration: none;
      color: yellow;
    }

    .about-info {
      margin-left: 2rem;
      h4 {
        font-size: 1.5rem;
      }
      .about-text {
        padding: 1rem 0;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    flex-direction: column;
    .about-info {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

.about-details {
  display: flex;
  p {
    padding: 0.3rem 0;
  }
  .right-section {
    margin-left: 2.3rem;
  }
}

.about-btn {
  padding: 1rem 2rem;
  background-color: #037fff;
  outline: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: #f0f5f9;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 2px;
  margin-top: 1rem;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0.3rem;
    left: 0;
    bottom: 0;
    background-color: #a4acc4;
    transition: all 0.4s ease-in-out;
  }
  &:hover::after {
    width: 100%;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 10rem;
  flex-wrap: wrap;
  @media screen and (max-width: 1270px) {
    margin: 2rem 5rem;
  }
  @media screen and (max-width: 1100px) {
    margin: 2rem 2rem;
  }
}
