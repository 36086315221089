.hero-text {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 3.6rem;
  span {
    color: #037fff;
  }
}

.h-sub-text {
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 1.3rem;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
  .icon-holder {
    margin-right: 1rem;
    .icon {
      font-size: 2.8rem;
      color: #526291;
      transition: all 0.4s ease-in-out;
    }
    .resume:hover {
      color: blue;
    }
    .gh:hover {
      color: rgba(212, 45, 212, 0.788);
    }
    .ln:hover {
      color: red;
    }
  }
}

.ContactForm {
  position: relative;
  width: 70%;
  height: 45%;
  margin-top: 5%;
  margin-left: 15%;
}
